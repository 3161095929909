import { CommonModule } from '@angular/common';
import { DialogoComponent } from './dialogo.component';
import { NbButtonModule, NbCardModule, NbDialogModule, NbListModule } from '@nebular/theme';
import { NgModule } from '@angular/core';

@NgModule({
  declarations: [
    DialogoComponent
  ],
  imports: [
    CommonModule,
    NbButtonModule,
    NbCardModule,
    NbDialogModule,
    NbListModule, 
  ]
})
export class DialogoModule { }