import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { AuthGuard } from './auth.guard';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { CustomHttpClient } from './customHTTPClient';
import { DialogoModule } from './shared/dialogo/dialogo.module';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { LOCALE_ID, NgModule } from '@angular/core';
import { NB_AUTH_TOKEN_INTERCEPTOR_FILTER, NbAuthModule, NbAuthSimpleInterceptor, NbAuthSimpleToken, NbPasswordAuthStrategy } from '@nebular/auth';
import { NbDatepickerModule, NbCardModule, NbContextMenuModule, NbDialogModule, NbIconModule, NbLayoutModule, NbMenuModule, NbSidebarModule, NbThemeModule, NbToastrModule, NbUserModule, NbListModule, NbButtonModule, NbStepperModule, NbWindowModule } from '@nebular/theme';
import { NbDateFnsDateModule } from '@nebular/date-fns';
import { NbEvaIconsModule } from '@nebular/eva-icons';
import { ReactiveFormsModule } from '@angular/forms';
import { registerLocaleData } from '@angular/common';
import localeBr from '@angular/common/locales/pt';
import { environment } from 'src/environments/environment.prod';

registerLocaleData(localeBr, 'pt');

const formSetting: any = {
  redirectDelay: 50,
  showMessages: {
    success: true,
  },
};

@NgModule({
  declarations: [AppComponent],
  imports: [
    AppRoutingModule,   
    NbDatepickerModule.forRoot(),
    BrowserAnimationsModule,
    BrowserModule,
    DialogoModule,
    HttpClientModule,
    NbButtonModule,
    NbCardModule,
    NbContextMenuModule,
    NbDateFnsDateModule,
    NbDialogModule.forRoot(),
    NbEvaIconsModule,
    NbIconModule,
    NbLayoutModule,
    NbListModule,
    NbMenuModule.forRoot(),
    NbSidebarModule.forRoot(),
    NbStepperModule,
    NbThemeModule.forRoot(),
    NbToastrModule.forRoot(),
    NbUserModule,
    NbWindowModule.forRoot(),
    ReactiveFormsModule,
    NbAuthModule.forRoot({
      strategies: [
        NbPasswordAuthStrategy.setup({
          name: 'email',
          baseEndpoint: environment.BACK_URL + '/auth',
          token:{ class: NbAuthSimpleToken, key: 'token'},
            login: {
              endpoint: '/login',
              method: 'post',
              redirect: {
                success: '/',
                failure: null,
              },
            },
            logout: {
              endpoint: '/logout',
              method: 'delete',
              redirect: {
                success: '/auth/login',
                failure: null,
              },
            }
        }),
      ],
      forms: {
        login: formSetting,
      },
    }), 
  ],
  providers: [
    AuthGuard,
    CustomHttpClient,  
    { provide: LOCALE_ID, useValue: 'pt' },
    { provide: NB_AUTH_TOKEN_INTERCEPTOR_FILTER, useValue: function () { return false; }, },
    { provide: HTTP_INTERCEPTORS, useClass: NbAuthSimpleInterceptor, multi: true },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}