export const environment = {
  production: true,
  url: 'https://api.superbox.acesso.ws',
  BACK_URL: 'https://api.superbox.acesso.ws',
  FRONT_URL: 'https://app.superbox24h.com.br'
 //BACK_URL: 'http://localhost:3000',
 //FRONT_URL: 'http://localhost:4200'

};

//estoque/predicoes

/*
{
  "diasFuturo": 15,
  "diasPassado": 16,
	"dataInicio": "15/02/2023",
	"dataFim": "31/02/2023",
  "produtos": [
    {
      "id": 1
    },
    {
      "id": 2
    }
  ]
}
*/

/*
[
	{
		"produtoId": 1,
		"qtdePrevista": 250
	},
	{
		"produtoId": 2,
		"qtdePrevista": 250
	},
	{
		"produtoId": 3,
		"qtdePrevista": 250
	}
]
*/

/*
db_host = 148.72.158.42
db_user = superbox
db_pass = Sup3dkaq3!
db_name = superbox
db_port = 5432
*/